import React, { useState, useEffect } from "react"
import { navigate } from "gatsby"
import Recaptcha from "react-recaptcha"
import { useMutation } from "@apollo/client"
import { OtherRegister } from "../../crud/createRegister"
import axios from "axios"

// Components
import { Input } from "../../components/styled"
import { toast } from "react-toastify"
import { Seo } from "../../components/seo"
import useSSR from "use-ssr"
const OtherForm = ({ wid }) => {
  const { isBrowser } = useSSR()
  const [register, setRegister] = useState({
    FullName: "",
    Email: "",
    ConfirmEmail: "",
    Country: "",
    CitizenshipCountry: "",
    NativeLanguage: "-",
    WorkID: "0",
    WorkCondition: "",
    form_type: 4,
    PrevRegister: "No",
    messageError: {},
    isVerified: false,
  })
  useEffect(() => {
    getIpInfo()
  })
  const [ip, setIp] = useState("No")
  const [ipAddress, setIpAddress] = useState("No")
  const getIpInfo = async () => {
    const res = await axios.get("https://geolocation-db.com/json/")
    setIp(res.data.IPv4)
    setIpAddress(res.data.country_name)
  }
  const [loading, setLoading] = useState(false)
  const { messageError } = register
  const emailRegex = RegExp(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/)
  const handleChange = e => {
    const { name, value } = e.target
    setRegister(prevState => ({
      ...prevState,
      [name]: value,
    }))

    let errors = register.messageError
    switch (name) {
      case "FullName":
        errors.FullName =
          value === "" ? "必須項目を必ずご記載の上、ご送信ください。" : ""
        break

      case "Email":
        errors.Email =
          value === ""
            ? "必須項目を必ずご記載の上、ご送信ください。"
            : "" || emailRegex.test(value)
            ? ""
            : "これは有効なメールではありません"
        break

      case "ConfirmEmail":
        errors.ConfirmEmail =
          value !== register.Email
            ? "必須項目を必ずご記載の上、ご送信ください。"
            : ""
        break

      case "PrevRegister":
        errors.PrevRegister =
          value === "" ? "必須項目を必ずご記載の上、ご送信ください。" : ""
        break
      default:
        break
    }
  }

  const [addOtherRegister] = useMutation(OtherRegister, {
    onCompleted: () => {
      navigate("/thankyou")
    },
    onError: () => {
      setLoading(false)
      toast.error("サーバーエラー")
    },
  })

  const handleRegisterOther = () => {
    addOtherRegister({
      variables: {
        input: {
          data: {
            FullName: register.FullName,
            Email: register.Email,
            Country: register.Country,
            CitizenshipCountry: register.CitizenshipCountry,
            NativeLanguage: register.NativeLanguage,
            WorkID: wid.workid,
            WorkCondition: register.WorkCondition,
            form_type: register.form_type,
            PrevRegister: register.PrevRegister,
            IpAddress: ip,
            IpCountry: ipAddress,
          },
        },
      },
    })
  }

  const [captchaVerify, setCaptchaVerify] = useState(false)
  const recaptchaVerify = response => {
    if (response) {
      setRegister(prevState => ({
        ...prevState,
        isVerified: true,
      }))
    } else {
      setCaptchaVerify(true)
      setTimeout(() => {
        setCaptchaVerify(false)
      }, 3000)
    }
  }

  const formValidate = (errors, ...data) => {
    let valid = true
    Object.values(errors).forEach(val => {
      val && (valid = false)
    })
    Object.values(data).forEach(val => {
      val === "" && (valid = false)
    })
    return valid
  }
  const handleSubmit = event => {
    event.preventDefault()
    if (register.isVerified) {
      if (
        register.FullName !== "" &&
        register.Email !== "" &&
        register.PrevRegister !== "" &&
        register.ConfirmEmail !== ""
      ) {
        if (formValidate(register.messageError, register)) {
          setLoading(true)
          handleRegisterOther()
        } else {
          toast.error("フォームが正しくありません")
        }
      } else {
        toast.error("入力内容を再度ご確認ください。")
      }
    } else {
      recaptchaVerify()
    }
  }

  return (
    <section className="container contact-us">
      <div className="row">
        <form className="contact-us__form-contact" onSubmit={handleSubmit}>
          <div className="form-responsive">
            <Input>
              <label htmlFor="氏名（フルネーム）">
                <p className="form-label">氏名（フルネーム） </p>
                <span>必須</span>
              </label>
              <input
                type="text"
                id="氏名（フルネーム）"
                name="FullName"
                onChange={handleChange}
                onBlur={handleChange}
              />
              {messageError.FullName && (
                <span className="field-validation">
                  {messageError.FullName}
                </span>
              )}
            </Input>

            <Input>
              <label htmlFor="メールアドレス">
                <p className="form-label">メールアドレス</p> <span>必須</span>
              </label>
              <input
                type="text"
                id="メールアドレス"
                name="Email"
                onChange={handleChange}
                onBlur={handleChange}
              />
              {messageError.Email && (
                <span className="field-validation">{messageError.Email}</span>
              )}
            </Input>
            <Input>
              <label htmlFor="メールアドレスの再入力">
                <p className="form-label">メールアドレスの再入力 </p>
                <span>必須</span>
              </label>
              <input
                type="text"
                id="メールアドレスの再入力"
                name="ConfirmEmail"
                onChange={handleChange}
                onBlur={handleChange}
              />
              {messageError.ConfirmEmail && (
                <span className="field-validation">
                  {messageError.ConfirmEmail}
                </span>
              )}
            </Input>
            <Input>
              <label htmlFor="在住国">
                <p className="form-label">在住国</p>
              </label>
              <input
                type="text"
                id="在住国"
                name="Country"
                onChange={handleChange}
              />
            </Input>
            <Input>
              <label htmlFor="国籍">
                <p className="form-label">国籍</p>
              </label>
              <input
                type="text"
                id="国籍"
                name="CitizenshipCountry"
                onChange={handleChange}
              />
            </Input>
            <Input>
              <label htmlFor="お住まいの州">
                <p className="form-label">母国語</p>
              </label>
              <select
                id="お住まいの州"
                name="NativeLanguage"
                onChange={handleChange}
                onBlur={handleChange}
              >
                <option value="-">-</option>
                <option value="日本語">日本語</option>
                <option value="スペイン語">スペイン語</option>
                <option value="英語">英語</option>
                <option value="その他言語">その他言語</option>
              </select>
            </Input>
            <Input>
              <label htmlFor="WorkCondition">
                <p className="form-label">
                  相談内容
                  <br />
                  <span>
                    ご希望面談日時や、ご希望条件などをご自由に記載ください。
                  </span>
                  <span>
                    *学生の場合は卒業時期を明記
                    <br />
                    *現在の在住国をベースとした面談可能日時を記載
                  </span>
                </p>
              </label>
              <textarea name="WorkCondition" onChange={handleChange} />
            </Input>
            <div className="captcha-style">
              {isBrowser && (
                <Recaptcha
                  render="explicit"
                  sitekey="6Lf4VfAbAAAAAO1Fu6ZqpDdvbs929UMuN04vwA3A"
                  name="isVerify"
                  verifyCallback={recaptchaVerify}
                />
              )}
              {captchaVerify ? (
                <span className="field-validation-captcha">
                  Please verify that you are not a robot
                </span>
              ) : (
                ""
              )}
            </div>
            <p>
              当社の
              <a
                className="btn-terms-condition"
                href="/termsofuse/"
                target="_blank"
                rel="noreferrer"
              >
                利用規約・個人情報保護方針
              </a>
              に同意の上、お申込みください。
            </p>
            <div className="u-text-center">
              {loading === false ? (
                <input type="submit" value="申し込む" className="btn-submit" />
              ) : (
                <p className="buttonload">
                  <i className="fa fa-circle-o-notch fa-spin"></i>
                  &nbsp;&nbsp;少々お待ちください
                </p>
              )}
            </div>
          </div>
        </form>
      </div>
    </section>
  )
}

export default OtherForm

export const Head = () => {
  return (
    <Seo
      title={`その他お申し込みフォーム`}
      url={`https://www.919mexico.com/job-form}`}
    />
  )
}
